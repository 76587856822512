"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorMessage = void 0;
var ErrorMessage;
(function (ErrorMessage) {
    ErrorMessage["INVALID_NUMBER"] = "invalid_number";
    ErrorMessage["INVALID_SNAKE_CASE"] = "invalid_snake_case";
    ErrorMessage["INVALID_PASCAL_CASE"] = "invalid_pascal_case";
    ErrorMessage["INVALID_ACTION"] = "invalid_action";
    ErrorMessage["INVALID_PRODUCT"] = "invalid_product";
    ErrorMessage["TRACKER_UNAVAILABLE"] = "tracker_unavailable";
})(ErrorMessage = exports.ErrorMessage || (exports.ErrorMessage = {}));
