"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const datalake_event_tracker_1 = require("@studyportals/datalake-event-tracker");
const tracking_actions_1 = require("../../enums/tracking-actions");
class RecommendationTracker {
    constructor() {
        this.dataLakeTracker = new datalake_event_tracker_1.Tracker(datalake_event_tracker_1.Product.RECOMMENDATONS);
    }
    trackStudyRecommendationsFallback(pageType, template) {
        const trackingData = {
            category: datalake_event_tracker_1.Product.RECOMMENDATONS,
            action: tracking_actions_1.TrackingActions.RECOMMENDATIONS_FALLBACK,
            label: pageType,
            property: template
        };
        this.snowPlow(trackingData);
    }
    trackStudyCardImpression(studyId, organisationId, order, page) {
        if (window['DatalakeEventTrackerLoaded'] !== true) {
            return;
        }
        const data = new datalake_event_tracker_1.StudyCardImpressionData(studyId, page, datalake_event_tracker_1.ListName.RECOMMENDER, organisationId, undefined, undefined, order, undefined, undefined);
        this.dataLakeTracker.trackStudyCardImpression(data);
    }
    trackModuleImpression(pageType, template) {
        const trackingParams = {
            action: datalake_event_tracker_1.Action.IMPRESSION,
            label: pageType,
            property: template
        };
        this.trackStructuredEvent(trackingParams);
    }
    trackCarouselChevronClick(chevronClickType, pagetype, templateType) {
        const trackingParams = {
            action: datalake_event_tracker_1.Action.CLICK,
            label: pagetype,
            property: templateType,
            value: chevronClickType
        };
        this.trackStructuredEvent(trackingParams);
    }
    trackRecommendationRendered(amount, pageType, templateType) {
        const trackingParams = {
            action: datalake_event_tracker_1.Action.RENDERED,
            label: pageType,
            property: templateType,
            value: amount
        };
        this.trackStructuredEvent(trackingParams);
    }
    trackRecommendationsGiven(recommendations, pageType, templateType) {
        const trackingData = {
            category: datalake_event_tracker_1.Product.RECOMMENDATONS,
            action: tracking_actions_1.TrackingActions.RECOMMENDATIONS_GIVEN,
            label: pageType,
            property: JSON.stringify({
                type: templateType,
                recommendations
            })
        };
        this.snowPlow(trackingData);
    }
    snowPlow(trackingParams) {
        if (typeof window["snowplow"] === "undefined") {
            return;
        }
        window["snowplow"]("trackStructEvent", {
            category: trackingParams.category,
            action: trackingParams.action,
            label: trackingParams.label || "",
            property: trackingParams.property || "",
            value: trackingParams.value || 0
        });
    }
    trackStructuredEvent(trackingParams) {
        if (window['DatalakeEventTrackerLoaded'] !== true) {
            return;
        }
        this.dataLakeTracker.trackStructuredEvent(trackingParams);
    }
}
exports.default = RecommendationTracker;
