"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PortalType = void 0;
var PortalType;
(function (PortalType) {
    PortalType["MASTERSPORTAL"] = "master";
    PortalType["BACHELORSPORTAL"] = "bachelor";
    PortalType["PHDPORTAL"] = "phd";
    PortalType["SHORTCOURSESPORTAL"] = "short";
    PortalType["DISTANCELEARNINGPORTAL"] = "distance_learning";
})(PortalType = exports.PortalType || (exports.PortalType = {}));
