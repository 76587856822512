"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleState = void 0;
var ModuleState;
(function (ModuleState) {
    ModuleState["INITIAL"] = "initial";
    ModuleState["LOADING"] = "loading";
    ModuleState["READY"] = "ready";
    ModuleState["FAILED"] = "failed";
})(ModuleState = exports.ModuleState || (exports.ModuleState = {}));
