"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getters = void 0;
const portal_type_1 = require("../enums/portal-type");
const getters = {
    isReady: (state) => state.isReady,
    userLoggedIn: (state) => state.userLoggedIn,
    identityId: (state) => state.identityId,
    userDegreeType: () => {
        const url = new URL(window.location.toString());
        if (url.host.includes('mastersportal')) {
            return portal_type_1.PortalType.MASTERSPORTAL;
        }
        if (url.host.includes('bachelorsportal')) {
            return portal_type_1.PortalType.BACHELORSPORTAL;
        }
        if (url.host.includes('phdportal')) {
            return portal_type_1.PortalType.PHDPORTAL;
        }
        if (url.host.includes('shortcoursesportal')) {
            return portal_type_1.PortalType.PHDPORTAL;
        }
        if (url.host.includes('distancelearningportal')) {
            return portal_type_1.PortalType.DISTANCELEARNINGPORTAL;
        }
        return 'master';
    }
};
exports.getters = getters;
