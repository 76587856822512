"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_property_decorator_1 = require("vue-property-decorator");
const vuex_1 = require("vuex");
const templates_types_1 = require("./enums/templates-types");
const recommendations_types_1 = require("./enums/recommendations-types");
let App = class App extends vue_property_decorator_1.Vue {
    constructor() {
        super(...arguments);
        this.showTemplate = false;
    }
    get isReady() {
        return this.$store.getters.isReady;
    }
    get identifier() {
        return String(this.$root.$props.identifier);
    }
    get recommendationType() {
        return String(this.$root.$props.recommendation_type);
    }
    get templateType() {
        return String(this.$root.$props.template_type);
    }
    get filters() {
        return this.$root.$props.filters;
    }
    get pageType() {
        return this.$root.$props.pageType;
    }
    get templates() {
        return templates_types_1.TemplateTypes;
    }
    get recommendationsTypes() {
        return recommendations_types_1.RecommendationsTypes;
    }
    get showBestFit() {
        return this.$root.$props.showBestFit;
    }
    mounted() {
        this.canShowTemplate();
    }
    canShowTemplate() {
        if (this.recommendationType && this.templateType && this.isReady) {
            this.showTemplate = true;
        }
    }
};
__decorate([
    vue_property_decorator_1.Watch('isReady')
], App.prototype, "canShowTemplate", null);
App = __decorate([
    vue_property_decorator_1.Component({
        components: {
            ScrollTemplate: () => Promise.resolve().then(() => __importStar(require("./components/Templates/ScrollTemplate/ScrollTemplate.vue"))),
            StudyProgrammes: () => Promise.resolve().then(() => __importStar(require("./components/Recommendations/StudyProgrammes/StudyProgrammes.vue"))),
            Scholarships: () => Promise.resolve().then(() => __importStar(require("./components/Recommendations/Scholarships/Scholarships.vue"))),
            Articles: () => Promise.resolve().then(() => __importStar(require("./components/Recommendations/Articles/Articles.vue")))
        },
        computed: vuex_1.mapGetters(['isLoggedIn'])
    })
], App);
exports.default = App;
