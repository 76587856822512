"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const impression_validator_1 = require("@studyportals/impression-validator");
const impression_tracking_base_1 = require("./impression-tracking-base");
class ImpressionTracker extends impression_tracking_base_1.ImpressionTrackerBase {
    constructor(parentContainerClass, elementClass, pageType, templateType) {
        super(pageType, templateType);
        this.parentContainerClass = parentContainerClass;
        this.elementClass = elementClass;
        this.parentContainer = document.querySelector(`.${parentContainerClass}`);
        this.bindImpressionTracking();
        this.impressionValidator = new impression_validator_1.ImpressionValidator(this.parentContainer, elementClass);
    }
    bindImpressionTracking() {
        if (!this.parentContainer) {
            console.warn('No studies found!');
            return;
        }
        Array.from(this.parentContainer.children).forEach((element, index) => {
            var _a, _b;
            const item = element;
            if (!((_a = item === null || item === void 0 ? void 0 : item.dataset) === null || _a === void 0 ? void 0 : _a.studyId) || !((_b = item === null || item === void 0 ? void 0 : item.dataset) === null || _b === void 0 ? void 0 : _b.organisationId)) {
                return;
            }
            const studyId = parseInt(item.dataset.studyId, 0);
            const organisationId = parseInt(item.dataset.organisationId, 0);
            const order = index + 1;
            item.addEventListener(impression_validator_1.EventNames.VALIDATED_IMPRESSION, () => {
                this.trackModuleImpression();
                this.trackImpression(studyId, organisationId, order);
            });
        });
    }
    manuallyProcessImpression() {
        this.impressionValidator.processElements();
    }
}
exports.default = ImpressionTracker;
