"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tracker = void 0;
const tracker_importer_1 = require("./tracker-importer");
const category_validator_1 = require("./validators/category-validator");
const event_validator_1 = require("./validators/event-validator");
const action_validator_1 = require("./validators/action-validator");
const label_validator_1 = require("./validators/label-validator");
const property_validator_1 = require("./validators/property-validator");
const value_validator_1 = require("./validators/value-validator");
const error_message_1 = require("../enums/error-message");
class Tracker {
    constructor(product) {
        this.product = product;
        this.tracker = null;
        this.trackerImporter = null;
        this.structuredEventValidator = this.initStructuredEventValidators();
        this.initTrackerSupport();
    }
    trackStructuredEvent(trackingData) {
        if (this.tracker === null) {
            throw new Error(error_message_1.ErrorMessage.TRACKER_UNAVAILABLE);
        }
        trackingData['category'] = this.product;
        this.structuredEventValidator.validate(trackingData);
        this.tracker('trackStructEvent', trackingData);
    }
    trackStudyCardImpression(trackingData) {
        if (this.tracker === null) {
            throw new Error(error_message_1.ErrorMessage.TRACKER_UNAVAILABLE);
        }
        this.tracker('addEnhancedEcommerceImpressionContext', trackingData.getTrackingContext());
        this.tracker('trackEnhancedEcommerceAction', {
            action: 'view'
        });
    }
    trackStudyCardClick(trackingData) {
        if (this.tracker === null) {
            throw new Error(error_message_1.ErrorMessage.TRACKER_UNAVAILABLE);
        }
        this.tracker('addEnhancedEcommerceProductContext', trackingData.getTrackingContext());
        this.tracker('trackEnhancedEcommerceAction', {
            action: 'click'
        });
    }
    trackProductView(trackingData) {
        if (this.tracker === null) {
            throw new Error(error_message_1.ErrorMessage.TRACKER_UNAVAILABLE);
        }
        this.tracker('addEnhancedEcommerceProductContext', trackingData.getTrackingContext());
        this.tracker('trackEnhancedEcommerceAction', {
            action: 'detail'
        });
    }
    initStructuredEventValidators() {
        const eventValidator = new event_validator_1.EventValidator();
        eventValidator.addValidator(new category_validator_1.CategoryValidator);
        eventValidator.addValidator(new action_validator_1.ActionValidator);
        eventValidator.addValidator(new label_validator_1.LabelValidator);
        eventValidator.addValidator(new property_validator_1.PropertyValidator);
        eventValidator.addValidator(new value_validator_1.ValueValidator);
        return eventValidator;
    }
    initTrackerSupport() {
        this.trackerImporter = new tracker_importer_1.TrackerImporter();
        this.trackerImporter.loadTracker(() => {
            this.tracker = this.trackerImporter.tracker;
            window['DatalakeEventTrackerLoaded'] = true;
            document.dispatchEvent(new Event('DatalakeEventTrackerLoaded'));
        });
    }
}
exports.Tracker = Tracker;
