"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudyCardImpressionData = void 0;
const ecommerce_tracking_data_class_1 = require("./ecommerce-tracking-data.class");
class StudyCardImpressionData extends ecommerce_tracking_data_class_1.EcommerceTrackingData {
    getTrackingContext() {
        return {
            id: this.getProductId(),
            name: this.listPageType,
            list: this.listName,
            brand: this.getOrganisationId(),
            category: this.getDisciplines(),
            variant: this.premium,
            position: this.listPosition,
            price: this.getUnitPrice(),
            currency: this.getCurrency()
        };
    }
}
exports.StudyCardImpressionData = StudyCardImpressionData;
