"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginStatusCheckerPlugin = void 0;
const student_interfaces_1 = require("@studyportals/student-interfaces");
const vue_property_decorator_1 = require("vue-property-decorator");
class LoginStatusCheckerPlugin {
    constructor(store) {
        this.store = store;
    }
    get eventAggregationService() {
        const eventAggregationService = vue_property_decorator_1.Vue.prototype.$eventAggregationService;
        if (!eventAggregationService) {
            return null;
        }
        return eventAggregationService;
    }
    notify(event) {
        if (event.eventType === student_interfaces_1.SessionCreatedEvent.EventType) {
            this.store.dispatch("setLoggedInState", true);
        }
    }
    initiate(sessionService) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.eventAggregationService) {
                return;
            }
            yield this.handleSessionService(sessionService);
            this.eventAggregationService.subscribeTo(student_interfaces_1.SessionCreatedEvent.EventType, this);
        });
    }
    handleSessionService(sessionService) {
        return __awaiter(this, void 0, void 0, function* () {
            const session = yield sessionService.getSession();
            if (session === null) {
                this.store.dispatch("setLoggedInState", false);
                return;
            }
            this.store.dispatch("setLoggedInState", true);
        });
    }
}
exports.LoginStatusCheckerPlugin = LoginStatusCheckerPlugin;
