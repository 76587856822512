"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecommendationClient = void 0;
const domain_client_1 = require("@studyportals/domain-client");
const warning_messages_1 = require("../enums/warning-messages");
class RecommendationClient {
    constructor() {
        this.recommendationEndpoint = RECOMMENDATIONS_URL;
        this.programmeCardClient = new domain_client_1.ProgrammeCardClient('url', 'key', this.getRandomInt(1, 100), this.getPortalMap());
    }
    getStudyData(identityId, amount, filters) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield fetch(this.getStudyRecommendationsURL(identityId, amount, filters));
                const resultJson = yield result.json();
                return resultJson.recommendations.map(recommendation => {
                    recommendation.card = this.programmeCardClient.fromDescription(recommendation.card);
                    return recommendation;
                });
            }
            catch (e) {
                console.error(e);
                console.warn(warning_messages_1.WarningMessages.FAILED_TO_RETRIEVE_PROGRAMME_RECOMMENDATIONS);
                return [];
            }
        });
    }
    getScholarshipsData(identityId, degreeType) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const degreeTypeString = degreeType === '' ? '' : "&degree=" + degreeType;
                const result = yield fetch(this.recommendationEndpoint + "/scholarships?userId=" + identityId + degreeTypeString);
                const resultJson = yield result.json();
                if (!resultJson.recommendations) {
                    return [];
                }
                return resultJson.recommendations;
            }
            catch (e) {
                console.warn(warning_messages_1.WarningMessages.FAILED_TO_RETRIEVE_SCHOLARSHIP_RECOMMENDATIONS);
                return [];
            }
        });
    }
    getArticleData(identityId, degreeType) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const degreeTypeString = degreeType === '' ? '' : "&degree=" + degreeType;
                const result = yield fetch(this.recommendationEndpoint + "/articles?userId=" + identityId + degreeTypeString);
                const resultJson = yield result.json();
                if (!resultJson.recommendations) {
                    return [];
                }
                return resultJson.recommendations;
            }
            catch (e) {
                console.warn(warning_messages_1.WarningMessages.FAILED_TO_RETRIEVE_ARTICLES_RECOMMENDATIONS);
                return [];
            }
        });
    }
    getPortalMap() {
        if (!window['PortalMap']) {
            return undefined;
        }
        return new Map(Object.entries(window['PortalMap']));
    }
    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
    }
    getStudyRecommendationsURL(identityId, amount, filters) {
        const url = new URL(`${this.recommendationEndpoint}/recommendations`);
        url.searchParams.append('userId', identityId);
        url.searchParams.append('amount', amount.toString());
        if (filters === null || filters === void 0 ? void 0 : filters.countries) {
            url.searchParams.append('countries', filters.countries);
        }
        if (filters === null || filters === void 0 ? void 0 : filters.disciplines) {
            url.searchParams.append('disciplines', filters.disciplines);
        }
        if (filters === null || filters === void 0 ? void 0 : filters.level) {
            url.searchParams.append('level', filters.level);
        }
        return url.toString();
    }
}
exports.RecommendationClient = RecommendationClient;
