"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mutations = void 0;
const mutations = {
    setAreDependenciesLoaded(state, areDependenciesLoaded) {
        state.areDependenciesLoaded = areDependenciesLoaded;
    },
    setIsReady(state, isReady) {
        state.isReady = isReady;
    },
    setLoggedInState(state, loggedInState) {
        state.userLoggedIn = loggedInState;
    },
    setIdentityId(state, identityId) {
        state.identityId = identityId;
    }
};
exports.mutations = mutations;
