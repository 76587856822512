"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_property_decorator_1 = require("vue-property-decorator");
let CarouselTemplate = class CarouselTemplate extends vue_property_decorator_1.Vue {
    constructor() {
        super(...arguments);
        this.carouselWrapper = null;
        this.cardTrack = null;
        this.index = 0;
        this.moduleWidth = 0;
        this.overflow = 0;
    }
    mounted() {
        this.$nextTick(() => {
            this.initCarousel();
        });
        // Reset carousel on resize
        window.addEventListener('resize', () => {
            this.initCarousel();
            this.navigateBackward();
        });
    }
    initCarousel() {
        if (window.innerWidth < 768) {
            this.index = 0;
            return;
        }
        this.carouselWrapper = this.$refs.carouselWrapper;
        if (!this.carouselWrapper)
            return;
        this.moduleWidth = this.carouselWrapper.clientWidth;
        // Stop initialization if there are no cards in the module
        if (this.moduleWidth === 0)
            return;
        this.cardTrack = this.carouselWrapper.querySelector('.js-cardsTrack');
        this.overflow = this.cardTrack.scrollWidth - this.carouselWrapper.clientWidth + 32;
    }
    navigateBackward() {
        if (this.index <= 0 || !this.cardTrack)
            return;
        this.index--;
        this.cardTrack.style.transform = `translateX(0)`;
    }
    navigateForward() {
        if (this.index >= 1 || !this.cardTrack)
            return;
        this.index++;
        this.cardTrack.style.transform = `translateX(-${this.overflow}px)`;
    }
};
CarouselTemplate = __decorate([
    vue_property_decorator_1.Component
], CarouselTemplate);
exports.default = CarouselTemplate;
