"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackerImporter = void 0;
class TrackerImporter {
    constructor() {
        this.trackerObject = null;
        this.trackerIsLoaded = false;
    }
    get tracker() {
        return this.trackerObject;
    }
    get trackerLoaded() {
        return this.trackerIsLoaded;
    }
    loadTracker(callback) {
        if (typeof window['snowplow'] !== 'undefined') {
            this.trackerObject = window['snowplow'];
            this.trackerIsLoaded = true;
            callback(window['snowplow']);
            return;
        }
        document.addEventListener("snowplow_available", () => {
            this.trackerObject = window['snowplow'];
            this.trackerIsLoaded = true;
            callback(window['snowplow']);
        });
    }
}
exports.TrackerImporter = TrackerImporter;
