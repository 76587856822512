"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WarningMessages = void 0;
var WarningMessages;
(function (WarningMessages) {
    WarningMessages["FAILED_TO_RETRIEVE_STUDENT_DATA"] = "failed to retrieve student data";
    WarningMessages["FAILED_TO_RETRIEVE_PROFILE_COMPLETENESS"] = "failed to retrieve profile completeness";
    WarningMessages["FAILED_TO_RETRIEVE_PROGRAMME_RECOMMENDATIONS"] = "failed to retrieve programme recommendations";
    WarningMessages["FAILED_TO_RETRIEVE_SCHOLARSHIP_RECOMMENDATIONS"] = "failed to retrieve scholarship recommendations";
    WarningMessages["FAILED_TO_RETRIEVE_ARTICLES_RECOMMENDATIONS"] = "failed to retrieve article recommendations";
})(WarningMessages = exports.WarningMessages || (exports.WarningMessages = {}));
