"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.state = void 0;
const state = {
    userLoggedIn: null,
    identityId: null,
    areDependenciesLoaded: false,
    isReady: false
};
exports.state = state;
