"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_property_decorator_1 = require("vue-property-decorator");
let BaseCarousel = class BaseCarousel extends vue_property_decorator_1.Vue {
    constructor() {
        super(...arguments);
        this.debounceTime = 0;
        this.frames = [];
        this.frameIndex = 0;
    }
    get trackElement() {
        return document.querySelector('.js-cardsTrack');
    }
    get breakpoints() {
        if (!window['Shared'])
            return null;
        return window['Shared'].breakpoints;
    }
    get trackMargin() {
        try {
            let trackMargin = window.getComputedStyle(this.trackElement).marginLeft;
            trackMargin = Number(trackMargin.replace(/\D/g, ''));
            return trackMargin;
        }
        catch (_a) {
            return 0;
        }
    }
    mounted() {
        this.init();
        window.addEventListener('resize', () => {
            this.resetTrack();
            this.init();
        });
    }
    init() {
        this.setCardWidth();
        this.calcFrames();
        this.setStyles();
    }
    setCardWidth() {
        const cardWidth = (this.getTrackViewPortWidth() / this.getCardCount()) - this.trackMargin;
        const cards = document.querySelectorAll('.js-studyCard');
        cards.forEach((card) => {
            card['style'].flex = `0 0 ${cardWidth}px`;
            card['style'].margin = `0.25rem 0 0 1rem `;
        });
    }
    getTrackViewPortWidth() {
        const container = document.querySelector('.js-StudyProgrammes');
        if (!container)
            return 0;
        return container.clientWidth - this.trackMargin;
    }
    getCardCount() {
        var _a, _b, _c;
        if ((_a = this.breakpoints) === null || _a === void 0 ? void 0 : _a.call(this, ['ExtraLarge'])) {
            return this.getTrackViewPortWidth() > 1080 ? 4 : 3;
        }
        if (((_b = this.breakpoints) === null || _b === void 0 ? void 0 : _b.call(this, ['Large'])) || ((_c = this.breakpoints) === null || _c === void 0 ? void 0 : _c.call(this, ['Medium']))) {
            return 2;
        }
        return 1;
    }
    /*
        Calculates all the frame transitions by dividing the total scrollable area by the carousel viewport.
        This will usually lead to an array of equal transitions, with the last transition smaller due to division remainder.
    */
    calcFrames() {
        var _a, _b;
        this.trackFullWidth = ((_a = this.trackElement) === null || _a === void 0 ? void 0 : _a.scrollWidth) - ((_b = this.trackElement) === null || _b === void 0 ? void 0 : _b.clientWidth);
        this.frameIndex = 0;
        let trackFollower = 0;
        const frameArray = [];
        const trackWidth = this.getTrackViewPortWidth();
        while (this.trackFullWidth > trackFollower) {
            if ((trackFollower + trackWidth) < this.trackFullWidth) {
                frameArray.push(trackWidth);
                trackFollower += trackWidth;
            }
            else {
                frameArray.push(this.trackFullWidth - trackFollower);
                trackFollower += this.trackFullWidth - trackFollower;
            }
        }
        this.frames = frameArray;
    }
    // Allows transition animation to complete before processing next click
    debounce(callbackFunction) {
        if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout);
        }
        this.debounceTimeout = setTimeout(() => {
            if (this.debounceTime < Date.now()) {
                callbackFunction();
                this.debounceTime = Date.now() + 400;
            }
        }, 200);
    }
    forwardClick() {
        this.processScroll(this.frames[this.frameIndex]);
        this.frameIndex++;
    }
    backClick() {
        this.frameIndex--;
        this.processScroll(-(this.frames[this.frameIndex]));
    }
    processScroll(translationPx) {
        var _a;
        (_a = this.trackElement) === null || _a === void 0 ? void 0 : _a.scrollBy({ top: 0, left: translationPx, behavior: 'smooth' });
    }
    resetTrack() {
        var _a;
        (_a = this.trackElement) === null || _a === void 0 ? void 0 : _a.scrollTo(0, 0);
    }
    setStyles() {
        var _a, _b, _c, _d, _e;
        const navButtons = document.querySelectorAll('.js-navButton');
        if ((_a = this.breakpoints) === null || _a === void 0 ? void 0 : _a.call(this, ['Medium', 'Small'])) {
            (_b = this.trackElement) === null || _b === void 0 ? void 0 : _b.classList.remove('NoScroll');
            (_c = this.trackElement) === null || _c === void 0 ? void 0 : _c.classList.add('Scroll');
            navButtons === null || navButtons === void 0 ? void 0 : navButtons.forEach(btn => {
                btn.classList.add('Hidden');
            });
        }
        else {
            (_d = this.trackElement) === null || _d === void 0 ? void 0 : _d.classList.remove('Scroll');
            (_e = this.trackElement) === null || _e === void 0 ? void 0 : _e.classList.add('NoScroll');
            navButtons === null || navButtons === void 0 ? void 0 : navButtons.forEach(btn => {
                btn.classList.remove('Hidden');
            });
        }
    }
};
BaseCarousel = __decorate([
    vue_property_decorator_1.Component
], BaseCarousel);
exports.default = BaseCarousel;
