"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewEcommerceTrackingData = void 0;
const ecommerce_tracking_data_class_1 = require("./ecommerce-tracking-data.class");
class ViewEcommerceTrackingData extends ecommerce_tracking_data_class_1.EcommerceTrackingData {
    constructor(productId, listPageType, listName, organisationId, disciplines, premium, listPosition, unitPrice, currency, productType) {
        super(productId, listPageType, listName, organisationId, disciplines, premium, listPosition, unitPrice, currency);
        this.productId = productId;
        this.listPageType = listPageType;
        this.listName = listName;
        this.organisationId = organisationId;
        this.disciplines = disciplines;
        this.premium = premium;
        this.listPosition = listPosition;
        this.unitPrice = unitPrice;
        this.currency = currency;
        this.productType = productType;
    }
    getTrackingContext() {
        return {
            id: this.getProductId(),
            name: this.productType,
            list: this.listName,
            brand: this.getOrganisationId(),
            category: this.getDisciplines(),
            variant: this.premium,
            price: this.getUnitPrice(),
            quantity: "1",
            coupon: this.listPageType,
            position: this.listPosition,
            currency: this.getCurrency()
        };
    }
}
exports.ViewEcommerceTrackingData = ViewEcommerceTrackingData;
